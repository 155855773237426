import React, {useState} from 'react'
import {FactoryData} from "./DatauseCases"

export const Factory = () => {
    const [isHovered, setIsHovered] = useState(false)

    return (
        <div>
           <div className="pageHeading pageHeadingFactory"><div><h1>RTLS in manufacturing and production</h1></div></div>

           <div className="introductionText"><p>Many manufacturing businesses are struggling with wasting resources and time because they do not have an accurate monitoring and controlling system for staff, assets, and workflows. This could result in rising operational expenses and overhead costs. Thanks to the real-time locating system(RTLS) for providing the possibility to track individuals and assets in real-time. Westigate RTLS is a locating solution that enables the precise location, inventory, and tracking of equipment, goods, and parts carriers and movable inventory. Also, the westigate sensory system can be integrated into the real-time locating system to monitor temperature, humidity, light, and power consumption over production lines and other site areas. Our engineering team has design the system with the most flexibility possible to be integrated and collect data from other sensors and PLCs as well. All location and sensory data will be collected and visualized on our web-based applications.

             Westigate wayfinding system is another solution developed by our team. It is an outdoor and indoor navigation system usable in big sites particularly when external forces need to deliver goods to a specific part of manufacturing sites.
                <br />
                <br/>
                Westigate wayfinding system is another solution developed by our team. It is an outdoor and indoor 
                navigation system usable in big sites particulary when external forces need to deliver goods to a specific part of manufacturing sites.  
                </p></div>

                <div onMouseOver={() => {setIsHovered(true)}} onMouseLeave={() => setIsHovered(false)} 
             className="textImage" style={{margin:"150px auto", backgroundColor:"rgba(0,0,255,0.6)"}}>
                <ul>
                <li><h3>improve resource management</h3></li>
                <li><h3>Save time and cost</h3></li>
                <li><h3>Prevent wasting resources</h3></li>
                <li><h3>Find items in a few seconds</h3></li>
                <li><h3>Improve security and safety</h3></li>
               </ul>
               <div className="sectorBenefitsImages">
                <img src="/factoryEngineer2.png" style={{transition:"filter 1s ease-in-out", filter: `${isHovered ? "grayscale(0%)" : "grayscale(100%)"}` }} alt=""/></div>
            </div>   



                <div style={{backgroundImage:`url("/factoryEngineer.png")`,  backgroundRepeat:"no-repeat", backgroundSize:"cover"}}>
                    {FactoryData.map(count => {
                        const {title, text, pic} = count
                        return(
                            <div className="textImage" style={{backgroundColor:"rgba(255,255,255,0.95", borderBottom:"1px solid rgba(0,0,0,0.1)"}}>
                            <div className="imageContainer"><div className="imageContainerCircle factoryApplicationsIcon"><img  src={pic} alt=""/></div></div>
                            <div className="textContainer"><h2>{title}<div className="underline" style={{margin:"10px 0"}}></div></h2><p>{text}</p></div>
                        </div>
                        )
                    })}
      
                </div>
        </div>
    )
}
