export const HealthcareData=[
    {
        category:"assetTracking",
        title:"Asset Tracking",
        text:"Managing critical assets in hospitals is crucial to deliver the best services to patients and effective business strategies. Westigate asset tracking system increases your insight on assets by converting location data to information. By doing this, the visibility provided by our system enhances the efficiency and productivity of the medical staff and your healthcare organization. The main concern of medical staff is to deliver the best services to patients. On the other hand, they have to deal with a high number of medical equipment to deliver their services. Thus, knowing where everything is can help them to accomplish their duties better. Westigate asset tracking system will able to track valuable assets such as medical and laboratory equipment, mobile equipment, and etc. Also, medical staff will receive an instant notification via email, phone, web service, if assets leave or enter a designated area, and they can find the location of each tagged object in a few seconds",
        pic:"/assetTracking.png",
        color:"lightgrey",
        textColor:"black"

    },
    {
        category:"onlineInventory",
        title:"Online Inventory",
        text:"Logistic processes in an inventory system in hospitals are a complex task including several steps from ordering goods to managing assets and finished goods. Thus, any shortcomings or lack of materials may lead to loss and cause many problems for healthcare providers. Thus, healthcare providers require a reliable system to gather information and be aware of the latest statue of inventory such as stock levels to be able to make logistic decisions. Our RTLS system provides a discrete online inventory for each building and floor with the possibility to set minimum and maximum thresholds in order to automate orders when you are low in stock.",
        pic:"/onlineInventory.png",
        color:"rgba(9, 9, 88, 1)",
        textColor:"white"

    },
    {
        category:"workFlowAutomation",
        title:"WorkFlow Automation",
        text:"Different processes in a hospital are complex and require consistent coordination between different tasks to deliver the best and fastest services to patients. Workflow automation helps healthcare providers to manage complicated processes in a hospital easier and save time. Westigate workflow automation system enables to create and monitor all tasks in an organized way. As a tangible example, our system can record doctors’ visits without staff intervention. We have provided a simple and user-friendly platform for users to create and control workflows with simple flowcharts without needing our support. Users will be able to create and customize workflows with connecting predefined nodes. ",
        pic:"/workflowAutomationIcon.png",
        color:"lightgrey",
        textColor:"black"

    },
    {
        category:"wayFinding",
        title:"WayFinding",
        text:"Visiting hospitals could be stressful for anyone who worries about the conditions of their relatives or themselves. Hospitals have a complex structure with several floors and sections, which sometimes make it confusing to find a unit, which could be a laboratory or CT Scan room. Thus, an indoor navigation system inside a hospital can reduce the stress of visitors to navigate an unit, and it increases customers’ satisfaction.Our navigation API provides an indoor and outdoor route from visitors’ starting point outside to a unit inside a hospital. It allows users to navigate in Real-Time while viewing their actual location anywhere on the campus.",
        pic:"/wayFinding.png",
        color:"rgba(9, 9, 88, 1)",
        textColor:"white"
    },
    {
        category:"geoFencing",
        title:"Geo-Fencing",
        text:"Geo-fencing is virtual borders to define geographical zones in applications that enable triggering events, such as e-mail alerts or SMS alerts, when an object enters or leaves a zone or passes maximum time for staying inside a designated area. According to your needs, zones can be a room, a floor, an unit, or a part of a room.",
        pic:"/geoFencing.png",
        color:"lightgrey",
        textColor:"black"

    },
    {
        category:"handHygiene",
        title:"Hand Hygiene",
        text:"Infection caused by poor hand hygiene of medical staff threatens tens of thousands of patients’ safety and costs millions of dollars for healthcare providers every year. Improving the medical staff’s hand hygiene is a priority to prevent extra cost and increase patients’ safety. By tracking staff and especially surgery room staff, the Westigate hand hygiene system presents an opportunity for healthcare organizations to ensure proper disinfection and minimize the spread of infection from the staff’s hands to patients.",
        pic:"/handHygiene.png",
        color:"rgba(9, 9, 88, 1)",
        textColor:"white"

    },
]

export const VideoText=[
    {
        title:"Patients Care Process Improvement",
        text:"Westigate RTLS provides the ultimate patient care solution bringing many benefits over healthcare. It helps healthcare workers to save time by reducing the time of searching for equipment and spending this time for patients to improve the care process. Optimizes care process with location data collected. Also, provides the possibility to visualize and monitor where a patient is in the care process.",
        videoLink:"https://player.vimeo.com/video/422418334" ,
        color:"rgba(255,255,255,0.1)"
    },
    {
        title:"Resource Management",
        text:"Efficient resource management has a key role in the care process. Missing resources and equipment while surgery is ongoing can make a delay in the care process, postpone the next procedure, and remain the room unusable. All of these potential delays can be removed with Westigate RTLS solution. Surgeons can check out the existing of all equipment needed prior to operation by using their laptop or cellphone. Nurses and other human resources receive notification about the schedule of upcoming tasks.",
        videoLink:"https://player.vimeo.com/video/422420946" ,
        color:"rgba(0,0,0,0.1)"
    },
    {
        title:"Patients' Experience Improvement",
        text:"Patients will receive an email or SMS with information about their appointment. They receive a link containing the outdoor path to reach the hospital and an indoor path inside the hospital to find the first room that their care process will start. ",
        videoLink:"https://player.vimeo.com/video/422422494" ,
    },
    {
        title:"Equipment Management",
        text:"On-time equipment maintenance and an efficient system for managing medical equipment can save a lot of money in hospitals. Healthcare workers and managers can assure all equipment services have been accomplished on time by using our solution. the technical staff become notified before the maintenance time of equipment. Moreover, Westigate system provides historical information regarding who and when has used equipment.",
        videoLink:"https://player.vimeo.com/video/422422983",
        color:"rgba(50, 218, 240, 0.1)"
    }
]


export const ConstructionApps = [
    {
        title:"Control construction project",
        text1:"RTLS solution gives you the ability to track and locate equipment, tools, and staff in a construction project in real-time. Managers can monitor all activities in a construction site without physical surveillance or checking reports. Managers and surveillance team can refer to the location records of any period to assure the right resources are in the right time and place. By doing this, they will be able to leverage and optimize resources. While time wasted to find items is a potential profit drain, staff have access to the real-time location of items through our RTLS applications.",
        pic:"/circle-controlTheSite.png",
    },
    {
        title:"Automate inventory",
        text1:"Managing materials and assets is always a big concern in construction projects. Besides, fear of running out of items makes over-ordering. Westigate RTLS applications provide a real-time inventory without human interaction. The system notifies associated staff once you are low in stock and helps you to plan for ordering materials on-time. ",
        pic:"/circle-inventory.png",

    },
    {   title:"Maximize safety and security",
        text1:"Construction work is a hazardous land-based job. Construction projects include many hazardous tasks and conditions such as working with height, excavation, power tools, and equipment. Hence, safety is always a priority in construction sites. Our RTLS applications notify you once individuals move in a risky place like under a load of a crane. Staff tags with an accelemeter sensor detect a fall. The system notifies others and records the exact location of a fall. Also, staff tags could be equipped with a panic button to be pressed in a panic situation, and the system notifies others of the exact location of the panic.",
        text2:"Also, the RTLS solution can help you to prevent theft. For example, staff will receive an instant notification once your expensive equipment exit a predefined zone without a permit.",
        pic:"/circle-safety.png",

    },
    {
    title:"Messages and alarms",
    text1:"The system can send notifications through SMS, email, and Westigate applications. According to the workflows configuration, staff receives different notifications. For example, the staff receives a notification to remind them about upcoming events.",
    pic:"/Location-alert.png",

},
{
    title:"Get detailed reports",
    text1:"Projects can fail for many reasons and one contributing factor can be an ineffective project reporting system. According to staff and equipment location, our RTLS solution provides accurate reports without human interaction and associated errors. This could be included a timesheet of employees and equipment, etc.",
    pic:"/circle-reports.png",
},
   
  ]


export const FactoryData = [
    {   
        title:"Locate Items and people",
        text:"In many manufacturing sites, staff wastes their time to find equipment and goods. Westigate RTLS applications visualize the location of tagged items in real-time. Staff can access the web-based locating application through any device connectable to the internet such as mobile phones. In addition to assets, the location of all tagged individuals and items will be recorded. Managers can refer to these records to make sure the right resources are used in the right place and time.",
        pic:"/Positioning.png",
    },
    {   
        title:"Online Inventory",
        text:"Managing materials and assets is always a big concern in manufacturing sites. Besides, fear of running out of items makes over-ordering. Westigate RTLS applications provide a real-time inventory without human interaction. The system notifies associated staff once you are low in stock and helps you to plan for ordering materials on-time.",
        pic:"/DepartmentLagerr.png",
    },
    {   
        title:"Workflow Automation",
        text:"Different processes in a factory are complex and require consistent coordination between different tasks. Workflow automation helps factories to manage complicated processes easier and save time. Westigate workflow automation system enables to create and monitor all tasks in an organized way. ",
        pic:"/workflowAutomationIcon.png",
    },
    {   
        title:"Maximizing Security and Safety",
        text:"Production lines and factories include many hazardous tasks and conditions such as hand tools, greasy floors, and storage racks. Hence, safety is always a priority in factories. Our RTLS applications notify you once individuals move into a risky place. Staff tags with an accelerometer sensor detect a fall. The system notifies others and records the exact location of a fall. Also, staff tags could be equipped with a panic button to be pressed in a panic situation, and the system notifies others of the exact location of the panic. The locating system tracks valuable items and notifies staff once an item exits predefined zones without a permit.",
        pic:"/securityIcon.png",
    }
    
]

