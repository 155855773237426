import React from 'react'
import "./hardware.css"
import {ReadersData} from "./dataHardware"

export const Readers = () => {
    return (
        <div>
        <div className="readersHeading"><h1>Westigate RTLS Readers</h1></div>
            {ReadersData.map(count =>{
                const{title, text, pic} = count
                return(
                    <div >
                        <h3 style={{borderBottom: "1px solid rgba(0,0,0,0.2)", width:"90%"}}>{title}</h3>
                        <div className="textImageReaders">
                        <div className="textContainerReaders"><p>{text}</p></div>
                        <div className="imageContainerReaders"><img src={pic} alt={title}/> </div>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}
