import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom'

function UseCases(){
    const [hovered1, setHovered1] = useState(false)
    const [hovered2, setHovered2] = useState(false)
    const [hovered3, setHovered3] = useState(false)
    const [widthWindow, setWidthWindow]   = useState(window.innerWidth);
    const updateDimensions = () => {
        setWidthWindow(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, []);

    const style1 = {
        position:`${widthWindow<800 ? "static" : "absolute"}`,
        width:`${widthWindow<800 ? "100%" : hovered1 ? "44.3%" : "33.3%"}`,
        backgroundImage:`url("/factoryHeadingPic.jpg")`,
        zIndex:`${hovered1 ? "2" : "1"}`,
        left:"0%",
        transition:"500ms ease-in-out",
    }
    const style2 = {
        backgroundImage:`url("/healthcare.jpg")`, 
        width:`${widthWindow<800 ? "100%" : hovered2 ? "44.3%" : "33.3%"}`,
        zIndex:`${hovered2 ? "2" : "1"}`, 
       left:`${hovered2 ? "28.3%" : "33.3%"}`,
       right:`${hovered2 ? "71.3%" : "66.3"}`, 
       transition:"500ms ease-in-out",
       position:`${widthWindow<800 ? "static" : "absolute"}`

    }
    const style3 = {
        backgroundImage:`url("/construction.png")`,
        width:`${widthWindow<800 ? "100%" : hovered3 ? "44.3%" : "33.3%"}`, 
        height:"500px", zIndex:`${hovered3 ? "2": "1"}`, 
        transition:"500ms ease-in-out",
        right:"0%",
        position:`${widthWindow<800 ? "static" : "absolute" }`,
        backgroundColor:"grey"
     }
    return (
        <div>
       <div style={{textAlign:"center", marginTop:"10px", justifyContent:"center", backgroundImage:`url("/IoT.jpg")`, backgroundSize:"cover", backgroundPosition:"center", width:"100%", color:"black", height:"150px"}}><h2 style={{alignContent:"center", paddingTop:"50px", minHeight:"100%",color:"white", backgroundColor:"rgba(0,0,0,0.7)"}}>Westigate Real-Time Locating System(RTLS) solution</h2></div>
    
        <div className="useCases" style={{position: `${widthWindow > 800 ? "relative" : "static" }`}}>
           
       <Link to='/pages/useCases/factory'><div className="useCasesDiv" onMouseOver={() => {setHovered1(true)}} 
            onMouseLeave={() => setHovered1(false)} 
            style={style1} >
            <div style={{color:"white", weight:"bold", backgroundColor:`${hovered1 ? "rgb(0, 0, 0, 0.85)" : "rgb(0, 0, 0, 0.7)"}`, minHeight:"300px"}} >
            <h5>Manufacturing</h5><div className="underline"></div>
            <p >Improve Efficiency And Safety In Manufacturing With Westigate RTLS</p></div>
            <button className="button">
                Find Out More</button>
        </div></Link>

       <Link to='/pages/useCases/healthcare'> <div className="useCasesDiv" onMouseOver={() => {setHovered2(true)}} onMouseLeave={() => setHovered2(false)} style={style2} >
            <div style={{color:"white", weight:"bold", backgroundColor:`${hovered2 ? "rgb(0, 0, 0, 0.85)" : "rgb(0, 0, 0, 0.7)"}`, minHeight:"300px"}} >
            <h5>Hospitals</h5><div className="underline"></div>
            <p >Smart Hospital with Westigate RTLS</p></div>
            <button className="button">
            Find Out More</button>


        </div></Link>


        <Link to='/pages/useCases/construction'><div className="useCasesDiv" onMouseOver={() => {setHovered3(true)}} onMouseLeave={() => setHovered3(false)} 
            style={style3} >
            <div style={{color:"white", weight:"bold", minHeight:"300px", backgroundColor:`${hovered3 ? "rgb(0, 0, 0, 0.85)" : "rgb(0, 0, 0, 0.7)"}`}} >
            <h5>Construction Sites</h5><div className="underline"></div>
            <p >Improve Productivity And Safety in Construction Sites with Westigate RTLS</p></div>
            <button className="button">
                Find Out More</button>

            </div></Link>

        </div>
        </div>
    )}


export default UseCases