export const DataHome =[ {
    title:"Healthcare",
    image:"/hospital_RTLS.jpg ",
    text:"Smart Hospital with Westigate RTLS",
    link:""
},
{
    title:"Elderly Houses",
    image:"/eldery_RTLS.jpg",
    text:"Improve Productivity And Safety in Elderly houses with Westigate RTLS",
    link:""
},
{
    title:"Constrcution",
    image:"/construction_RTLS.jpeg",
    text:"Improve Productivity And Safety in Your Construction Site with Westigate RTLS",
    link:""
},
]

export const Implementation=[
    {
        title:"Real-Time Operations Value Analysis",
        text:"We will work with you to identify challenges, strategize objectives and prioritize improvement areas."
    },
    {
        title:"Advanced Process Flow Design",
        text:"Together, we’ll analyze existing workflows, identify success factors and barriers, and recommend future state."
    },
    {
        title:"Process Modeling",
        text:"Your end-users will experience and experiment with the technology, new workflows and solution design."
    },
    {
        title:"Integrated Program Management",
        text:"Following a successful implementation, our team can provide ongoing management of your RTLS-based solution and processes."
    },
    {
        title:"Implementation Services",
        text:"We are here through every step of your implementation – from requirements, mapping and timelines, to design, configuration and go-live – to deliver the solution that meets your goals."
    },
    {
        title:"Training",
        text:"Our comprehensive portfolio of training tools support your initial implementation and beyond to ensure you use your solution to its full potential."
    },
    {
        title:"Technical Support",
        text:"Our technical support team ensures your solution is running smoothly, providing 24/7 support."
    },
    {
        title:"Product Maintenance",
        text:"Your annual service plan ensures you are continually improving your solution’s performance and value with the latest product updates and upgrades."
    },
    {
        title:"Integrations",
        text:"Our team has the experience and knowledge to create new custom integrations tailored to consolidate, automate and streamline various processes across your healthcare and other operational systems."
    },
    {
        title:"Mobile Applications and Live Boards",
        text:"Custom user interfaces that support a variety of platforms can be developed to keep connected to the most relevant, current and accurate information possible."
    },
    {
        title:"Data and Reporting",
        text:"Let our team of data engineers provide exactly what you need to enable visibility to historical events and trending information crucial to driving operational efficiency and patient satisfaction."

    },
    {
        title:"Development Support",
        text:"Use Westigate´s APIs to build your own integrations, mobile applications or reports with the help of our experienced team."
    },
]

export const DataBenefits=[
    {   index:"15",
        pic:"/location.png",
        text:"RTLS save time and cost by providing high accuracy to locate items in real-time ",
    },
    {   index:"16",
        pic:"/CostValueIcon.png",
        text:"Reduce costs by preventing resources waste",
    },
    {
        index:"17",
        pic:"/productivityIcon.png",
        text:"Increase productivity and effciency",
    },
    {
        index:"18",
        pic:"securityIcon.png",
        text:"Improve safety and security",
    },
    {
        index:"19",
        pic:"/staffIcon.png",
        text:"Help managers to control employees' activities",
    },
    {    index:"20",
        pic:"/workFlowsIcon.png",
        text:"Improve workflows",
    },
]

