import React, {useState} from 'react'
import "./useCases.css"
import {ConstructionApps} from "./DatauseCases"

const Construction = () => {
    const [isHovered, setIsHovered] = useState(false)
    return (
        <div>
            <div style={{width:"100%", height:"500px"}}>
                <div className="pageHeading pageHeadingConstruction" ><div>
                    <h1>westigate RTLS in construction projects</h1></div></div></div>

               <div className="introductionText"> <p >Utilizing Real-Time Locating System (RTLS) in construction sites can provide site managers with immediate benefits in different sectors. Imagine as a site manager you will be able to see anything happening in your site in real-time. You can have access to the list of existing machines and tools on your site in a few seconds, see a history of your staff activity in a certain period, etc. Our RTLS infrastructure will gather all information you are required from tagged objects and you have access to this information through our web-based interfaces via any device connectable to the internet.</p>
               </div>
               <div className='vid-wrapper'>
                <iframe  src="https://player.vimeo.com/video/413076536" 
                width="100%" height="100%"
                 frameborder="0" 
                 allow="autoplay; fullscreen" 
                 allowfullscreen 
                 title="Benefits of using RTLS in construction sites"
                /></div>

             <div onMouseOver={() => {setIsHovered(true)}} onMouseLeave={() => setIsHovered(false)} 
             className="textImage" style={{marginTop:"100px", backgroundColor:"grey"}}>
                <ul>
                <li><h3>improve resource management</h3></li>
                <li><h3>Save time and cost</h3></li>
                <li><h3>Prevent wasting resources</h3></li>
                <li><h3>Find items in a few seconds</h3></li>
                <li><h3>Improve security and safety</h3></li>
               </ul>
               <div className="sectorBenefitsImages">
               <img src="/constructionEngineer.png" style={{transition:"filter 1s ease-in-out", filter: `${isHovered ? "grayscale(0%)" : "grayscale(100%)"}` }} alt=""/></div>
            </div>   

            <div style={{justifyContent:"center", marginTop:"100px", alignContent:"center", backgroundImage:`url("/constructionEngineer.png")`, backgroundSize:"cover", backgroundPosition:"left"}}>
                {ConstructionApps.map(count => {
                  const {title, text1, text2, text3, pic} = count
                  return(
                        <div className="textImage" style={{borderBottom:"1px solid rgba(0,0,0,0.1)", backgroundColor:"rgba(255,255,255,0.90)"}}>
                        <div className="imageContainer" style={{marginTop:"10px", marginBottom:"10px"}}><img  className="image" style={{width:"60%"}} src={pic} alt={title} />
                        </div>
                        <div className="textContainer" style={{marginTop:"100px"}}><h3 style={{textAlign:"center"}}>{title}<div className="underline"></div></h3>
                        <div ><p>{text1}</p>
                        <p>{text2}</p>
                        {{text3} && (<p>{text3}</p>)} </div>
                       </div></div>
                       )
                })}
            </div>
        </div>
        
    )
}


export default Construction