import React from 'react';

const radius = 8;
const width = window.innerWidth*0.98;
const height = window.innerHeight/1.5;

class Animation extends React.Component {
    constructor(props) {
      super(props);
      this.state = 
          {
            circle : [{x:Math.random() * (width- radius*2) + radius, dx:(Math.random() - 0.5)*3, y:Math.random() * (height  - radius * 2) + radius, dy:(Math.random() - 0.5)*3},
                      {x:Math.random() * (width- radius*2) + radius, dx:(Math.random() - 0.5)*3, y: Math.random() * (height - radius * 2) + radius, dy:(Math.random() - 0.5)*3}, 
                      {x:Math.random() * (width- radius*2) + radius, dx:(Math.random() - 0.5)*3, y:Math.random() * (height  - radius * 2) + radius, dy:(Math.random() - 0.5)*3},
                      {x:Math.random() * (width- radius*2) + radius, dx:(Math.random() - 0.5)*3, y:Math.random() * (height  - radius * 2) + radius, dy:(Math.random() - 0.5)*3},
                      {x:Math.random() * (width- radius*2) + radius, dx:(Math.random() - 0.5)*3, y:Math.random() * (height  - radius * 2) + radius, dy:(Math.random() - 0.5)*3},
                      {x:Math.random() * (width- radius*2) + radius, dx:(Math.random() - 0.5)*3, y:Math.random() * (height  - radius * 2) + radius, dy:(Math.random() - 0.5)*3}
                    ],
            }
      this.updateAnimationState = this.updateAnimationState.bind(this);
                         }
  
    componentDidMount() {
      this.rAF = requestAnimationFrame(this.updateAnimationState);
    }
  
    updateAnimationState() {
      
          this.setState(state => {
          const circle = state.circle.map( count => {
             count.dx = (count.x+radius > width || (count.x-radius<0)) ? -count.dx : count.dx
             count.dy = (count.y+radius > height  || (count.y-radius<0)) ? -count.dy : count.dy
             count.x= count.x+count.dx
             count.y = count.y+count.dy })
          return circle
          })

      this.rAF = requestAnimationFrame(this.updateAnimationState);
    }
  
    componentWillUnmount() {
      cancelAnimationFrame(this.rAF);
    }
  
    render() {
      return <div><Canvas circle={this.state.circle} ></Canvas></div>
    }
  }

  export default Animation


  class Canvas extends React.Component{
    constructor(props) {
      super(props);
      this.state = {
                color : "grey",
                  }

        this.canvasRef = React.createRef();
        this.drawLine = this.drawLine.bind(this)
    }
    drawLine(ctx, x, y){
        let z = (Math.sqrt(Math.pow( x, 2) + Math.pow( y, 2))) - (Math.sqrt(Math.pow((width -  x), 2) + Math.pow((height -  y), 2)));
        let t = (Math.sqrt(Math.pow( x, 2) + Math.pow((height -  y), 2))) - (Math.sqrt(Math.pow((width -  x), 2) + Math.pow(( y), 2)));
        ctx.moveTo( x,  y);
        ctx.lineTo(width/2, height/2);
        ctx.stroke();
       if(z > 0){
        ctx.moveTo(x, y)
        ctx.lineTo(width, height)
        ctx.stroke()
       } 
       if (z < 0) {
        ctx.moveTo(x,  y);
        ctx.lineTo(0, 0);
        ctx.stroke();
    
    }
    if (t > 0) {
        ctx.moveTo(x,  y);
        ctx.lineTo(width, 0);
        ctx.stroke();
    }
    if (t < 0) {
        ctx.moveTo(x,  y);
        ctx.lineTo(0, height);
        ctx.stroke();
    }
    
      } 
    
    componentDidUpdate() {

      const canvas = this.canvasRef.current;
      const ctx = canvas.getContext('2d');
      canvas.width = width
      canvas.height = height
      ctx.save()
      ctx.clearRect(0,0, canvas.width, canvas.height)

      ctx.strokeStyle = this.state.color

      this.props.circle.map(count => {
          const {x, y} = count
          ctx.beginPath()
          ctx.arc(x, y, radius, 0, Math.PI * 2, false)
          ctx.fillStyle = this.state.color;
          ctx.fill();
          ctx.stroke();
          this.drawLine(ctx, x, y)    
      })

        ctx.restore();
      }

    render() {
      return (
          
        <div>
          <canvas ref={this.canvasRef}></canvas>
        </div>
      );
    }
  }