import React from 'react';
import { FaTimes } from 'react-icons/fa';
import { useGlobalContext } from './context';
import sublinks from './data';
import {Link} from 'react-router-dom'



const Sidebar = () => {
  const { isSidebarOpen, closeSidebar } = useGlobalContext();
  return (
    <div
      className={`${
        isSidebarOpen ? 'sidebar-wrapper show' : 'sidebar-wrapper'
      }`}
    >
      <aside className='sidebar'>
        <button className='close-btn' onClick={closeSidebar}>
          <FaTimes />
        </button>
        <div className='sidebar-links'>
          {sublinks.map((item) => {
            const { links, page, pageLink} = item
            return (
              <article>
                <Link style={{color:"white"}} to={pageLink}><h4>{page}</h4></Link>
                <div className='sidebar-sublinks'>
                 {links.map((link) => {
                    const { url, label, index} = link
                    return (
                      <li key={index}><Link style={{fontSize:"17px", color:"white"}}to={url}>
                        {label}
                       </Link>
                       </li>
                           );
                  })}
                </div> 
              </article>
            );
          })}
        </div>
      </aside>
    </div>
  );
};

export default Sidebar;
