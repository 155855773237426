import React, { useState } from 'react'
import DataTags from "./dataHardware"
import "./hardware.css"
import {BsFillPeopleFill} from 'react-icons/bs'
import {AiFillTags} from 'react-icons/ai'
import {GiMovementSensor} from 'react-icons/gi'
import {FaWheelchair} from 'react-icons/fa'


export const Tags = () => {
    const [tags, setTags] = useState(DataTags)

    const filterCategory = (category) =>{
        if (category==="all") {
            setTags(DataTags)
        }else{
       const newTags = DataTags.filter((count) => count.category === category)
       setTags(newTags)
    }}

    return (

        <div>
            <div className="tagsHeading"><h1>Westigate RTLS Tags</h1></div>
            <MenuTags filterCategory={filterCategory}/>
            <TagsInfo items={tags}/>
        </div>
    )
}



export const MenuTags = ({filterCategory}) => {
    return (
            <div className="MenuTags">
                <ul>
                   <li  onClick={() => filterCategory("all")}><i><AiFillTags fontSize="100px"/>
                   </i><br /><h4 style={{textAlign:"center"}}>All Tags</h4></li> 
                   <li  onClick={() => filterCategory("wearable")}><i><BsFillPeopleFill fontSize="100px"/>
                   </i><br /><h4 style={{textAlign:"center"}}>Wearable Tags</h4></li> 
                   <li  onClick={() => filterCategory("assets")}><i><FaWheelchair fontSize="100px"/>
                   </i><br /><h4 style={{textAlign:"center"}}>Asset Tags</h4></li> 
                   <li  onClick={() => filterCategory("sensors")}><i><GiMovementSensor fontSize="100px"/>
                   </i><br /><h4 style={{textAlign:"center"}}>Sensors</h4></li> 
              </ul>
            </div>
    )
}


export const TagsInfo = ({items}) => {
    return( 
        <div className="tagsInfo">
        {items.map(count => {
        const {title, text, pic} = count
        return(
            <div className="colTags">
                <h3>{title}</h3>
                <div className="imageContainerTags"><img src={pic} alt={title} /> </div>
                <p>{text}</p>
           </div>
                )
                            }
    )
    }
    </div>
    )
   }