import React from 'react'
import {HealthcareData} from "./DatauseCases"
import {VideoText} from "./DatauseCases"

const Healthcare = () => {
    return (
        <div>
           <div class= "rtls-an"><h2> Westigate RTLS Solution improves <span class="span-words"></span></h2>
           </div>
           <div>
               <img src="/nurse.png" alt=""></img>
           <h2 style={{textAlign:"center", marginTop:"-20%"}}>RTLS Appplications in healthcare</h2>
           </div>

         <div style={{justifyContent:"center", alignContent:"center"}}>
            <div>{HealthcareData.map(count => {
                  const {title, text, pic, color, textColor} = count
                  return(
                        <div className="healthcareApps" style={{backgroundColor:`${color}`, color:`${textColor}`}}>
                        <div style={{marginTop:"100px"}}>
                            <h3 style={{textAlign:"center"}}>{title}<div className="underline"></div></h3>
                        <div style={{textAlign:"left"}}><p>{text}</p></div>
                       </div>
                       <img style={{width:"100px", height:"100px"}} src={pic} alt=""/>
                       </div>
                       )
                })}
            </div>
            </div>
            <div>
                <h2 style={{textAlign:"center", marginTop:"200px"}}>RTLS benefits<div className="underline"></div></h2>
            {VideoText.map(count => {
                const{title, text, videoLink, color} = count
                return(
                    <div>
                        <div  className="textImage" style={{backgroundColor:`${color}`}}>
                        <div className="textContainer" style={{textAlign:"center"}}>
                        <h3>{title}<div className="underline"></div></h3>
                        <p style={{textAlign:"left"}}>{text}</p>
                        </div>
                    <div className="imageContainer" style={{height:"50vh"}} >
                    <iframe src={videoLink} 
                    width="100%" height="100%" 
                     frameborder="0" 
                     allow="autoplay; fullscreen" 
                     allowfullscreen
                     title="Benefits of using RTLS in hospitals"
                     />                       
                      </div>
                        </div>
                 </div>
                )
            })}          

            </div>

        </div>
    )
}

export default Healthcare