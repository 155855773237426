import React from 'react'


function Software(){
    return (
        <div style={{justifyContent:"center", alignItems:"center", textAlign:"center"}}>
            <div className="pageHeading" style={{backgroundImage:`url("/c1.png")`, backgroundColor:"black", justifyContent:"left"}}>
                <h1 style={{marginLeft:"40px"}}>Westigate RTLS Software</h1>
            </div>

            <div style={{marginTop:"100px"}}>
            <div className='vid-wrapper'>
                <iframe  src="https://player.vimeo.com/video/422414852" 
                width="100%" height="100%"
                 frameborder="0" 
                 allow="autoplay; fullscreen" 
                 allowfullscreen 
                 title="Features of the Westigate RTLS applications "
                /></div>
            <div className="introductionText" style={{textAlign:"left", marginTop:"50px"}}><p>Our customers  are able to customize, manage, and control the Real-Time Locating System through web-based applications. Westigate RTLS software provides user-friendly and highly 
                flexible platforms to address all requirements to manage and control your facilities.  According to your needs and infrastructure, we implement the RTLS engine on premise or cloud server. </p></div>
            </div>
            <div className="textImage" style={{borderBottom:"1px rgba(0,0,0,0.1) solid", marginTop:"150px"}}>
                <div className="textContainer">
                <h2 style={{textAlign:"center"}}>AC1-RTLS Manager<div className="underline"></div></h2>
                <p>
                AC1 is the core of the system to configure and employ the system. All tags, RTLS readers, and objects are registered and configured through this application. Admins upload and scale maps to be used in other applications. 
                This application provides the possibility to customize and manage workflows according to each organization requirements independently.
                </p></div>
                <div className="imageContainer"><img className="image" src="/admin.png" alt=""/></div>
            </div>

            <div className="textImage" style={{borderBottom:"1px rgba(0,0,0,0.1) solid"}}>
                <div className="textContainer">
                <h2 style={{textAlign:"center"}}>C1 Application<div className="underline"></div></h2>
                <p>
                C1 application provides the real-time location of tagged people and assets in real-time in list, graph, 2D and Isometric 3D map. This application provides heat-map view in 2D and isometric 3D, which gives you the vision of people and assets in a hot zone over a period.
                The application users can filter tagged objects displayed, track a specific object, and save the location data in Excel format for further uses. 
                </p> </div>
                <div className="imageContainer"><img className="image" src="/c1.png" alt=""/></div>
            </div>

            <div className="textImage" >
                <div className="textContainer">
                <h2 style={{textAlign:"center"}}>C2 Application<div className="underline"></div></h2>
                <p>
                Users of the system can refer to C2 application to access the location history of tagged objects, notifications, and the real-time inventory. Users can filter the location records to track a specific object over a given time. 
                These location records can help managers to have better understanding of resources used in each part and leverage them. By doing this, managers make sure right resources are used in the right time and right place.
                The hand hygiene and infection tracing tools of the application notify managers once associated policies are ignored by staff in hospitals.  
                
                </p> </div>
                <div className="imageContainer"><img className="image" src="/c2.png" alt=""/></div>
            </div>
        </div>
    )
}

export default Software
