export const ReadersData = [
    {
    title:"WGL20102",
    text:"WGL20102 is a Bluetooth gateway supporting MQTT, HTTP/HTTPS, and can scan 300 Bluetooth devices per second.  This gateway reads data from Eddystone, BLE sensors and other BLE devices and upload to a server through WIFI or Ethernet cable. It can be powered by POE.",
    pic:"/wg100.png",
    },
    {
    title:"WGL20101",
    text:"WGL20101 gateways scan BLE beacon data and upload to a server through WiFi.It appears like a smart plug outlet with US/EU/UK/ AU/FR kind. With the regular plug outlet size and also light-weight.",
    pic:"/wp.png",
    },
    {
    title:"WGL20201",
     text:"WGL20201 is a Bluetooth gateway supporting MQTT, HTTP/HTTPS.  This gateway reads date from Eddystone, BLE sensors and other Bluetooth devices and upload to a server through WIFI or Ethernet cable. It can be powered by POE.",
     pic:"/wg102.png",
    },
        
]

const DataTags = [
    {
        category:"assets",
        title:"WGL10101",
        text:"WGL10101 is IP65 Waterproof and Compatible with iBeacon and Eddystone. The battery lifetime is between 30 to 40 month.",
        pic:"/wh1.png"
    },
    {
        category:"sensors",
        title:"WGL10102",
        text:"WGL10102 is an IP66 temperature and humidity beacon with 3-axis accelerometer sensor. WGL10102 can store up to 4000 historical data and can be a iBeacon and Eddystone in turn.",
        pic:"/wht2.png",
    },
    {
        category:"sensors",
        title:"WGL10103",
        text:"WGL10103 is an IP66 temperature and humidity beacon with 3-axis accelerometer sensor. WGL10103 can store up to 4000 historical data and can be a iBeacon and Eddystone in turn.",
        pic:"/wht1.png",
    },
    {
        category:"wearable",
        title:"WGL10104",
        text:"WGL10104 is IP67 Waterproof and wearable bracelet shape. WGL10104 has 3-axis accelerometer sensor with motion trigger and can be charged directly via USB. It is compatible with iBeacon and Eddystone.",
        pic:"/ww2.png"
    },
    {   
        category:"wearable",
        title:"WGL10105",
        text:"WGL10105 can be used with Neck chain and bracelet With possibility to add motion sensors and RFID. It is compatible with iBeacon and Eddystone.",
        pic:"/ww3.png"
    },
    {
        category:"wearable",
        title:"WGL10106",
        text:"WGL10106 can be a bracelet and keyring with possibility to broadcast in 30 m distance. It has possibility to add motion sensors and RFID. It is compatible with iBeacon and Eddystone.",
        pic:"/ww1.png"
    },
    {   
        category:"assets",
        title:"WGL10107",
        text:"WGL10107 beacon is IP65 Waterproof and compatible with iBeacon and Eddystone.",
        pic:"/WH2.png"
    },
    {
        category:"assets",
        title:"WGL10108",
        text:"WGL10108 is a coin size beacon compatible with iBeacon and Eddystone.",
        pic:"/WM1.png"
    },
    {
        category:"wearable",
        title:"WGL10109",
        text:"WGL10109 is a Bluetooth 5.0 beacon which with hidden push button, 3-axis accelerometer sensor, RFID tag. The push button can be used to the panic / emergency / SOS button.",
        pic:"/WCA1.png"
    },
    {
        category:"assets",
        title:"WGL10201",
        text:"WGL10201 is an Indoor Beacon loaded with Bluetooth 5.0 hardware platform. The detachable housing structure makes i10 Indoor Beacon easy to replace batteries. It is also with battery insulation sheet, thus it can save battery power during shipment. Besides, it can be fixed to the objects by screws mounting.",
        pic:"/wis1.png"
    },
    {
        category:"assets",
        title:"WGL10202",
        text:"WGL10202 is a Robust Beacon loaded with Bluetooth 5.0 hardware platform . WGL10202 Beacon is IP66 waterproof grade. It not only can be used for indoor location but also for outdoor. Besides, it can be fixed to the objects by screws mounting, so it is stronger and safer than adhesives when install in some public places.",
        pic:"/wis2.png"
    },
    {
        category:"assets",
        title:"WGL10203",
        text:"WGL10203 Beacon is loaded with Bluetooth 5.0 hardware platform. Super-long range and 10 years battery lifetime make it ideal for shopping malls, airports and railway stations.",
        pic:"/we4.png"
    },
    {
        category:"assets",
        title:"WGL10204",
        text:"WGL10204 Tag beacon is the tiny battery-powered bluetooth 5.0 Beacon and also with 3-axis accelerometer sensor for option, used primarily for asset tracking and inventory tracking.",
        pic:"/WH3.png"
    },
    {
        category:"assets",
        title:"WGL10205",
        text:"WGL10205 is a Beacon with an easy-operate push button, which can be defined to advertise different data by clicking, widely used on the panic / emergency / SOS application",
        pic:"/WM2.png",
    },
    {
        category:"sensors",
        title:"WGL10206",
        text:"WGL10206 is a IOT and light sensor. The hardware is based on Ultra-Low Power.",
        pic:"/wel1.png",
    },
    {   
        category:"wearable",
        title:"WGL10207",
        text:"WGL10207 is an IP66 waterproof beacon with magnet switch to turn on/off applicable in activity monitoring through personnel and patients tracking. The disposable materials used in the product can maximize recycling.",
        pic:"/wp1.png"
    },
    {
        category:"wearable",
        title:"WGL10208",
        text:"WGL10208 is a IP66 waterproof beacon for patient and personnel tracking. The push button on the beacon is usable for emergency calls.",
        pic: '/WP2.png',
    },
    {
        category:"wearable",
        title:"WGL10209",
        text:"WGL10209 beacon is IP67 Waterproof and compatible with iBeacon and Eddystone. The battery lifetime is 3 years.",
        pic:"/wca2.png"
    },
    {
        category:"assets",
        title:"WGL10211",
        text:"WGL10211 can be plugged into a laptop, desktop or any micro USB port supply. It can be used in different use cases such as digital signage and video walls in shopping centers and bus station, sometimes need the beacon advertising to change in real-time while the display information changes.",
        pic:"/WU1.png",
    },
    {
        category:"assets",
        title:"WGL10212",
        text:"WGL10212 USB beacon can be plugged into a laptop, desktop or any micro USB port supply. It can be used proximity marketing solution such as in store, bus station and events etc.",
        pic:"/WU2.png",
    },
    {
        category:"wearable",
        title:"WGL10213",
        text:"WGL10213 with a NFC chip inbuilt can be used for personnel management and one the move advertising because of its portable and lightweight design.",
        pic:'/wp3.png',
    },


    {
        category:"wearable",
        title:"WGL10215",
        text:"WGL10215 is a disposable detachable wristband with IP67 Waterproof standard and a push button for hospital patients, staff positioning and emergency call. It is equipped with additional motion suit for fall detection.",
        pic:"/WW4.png"
    },
    {
        category:"wearable",
        title:"WGL10216",
        text:"The WGL10216 wristband is a Bluetooth bracelet beacon with cut-off alerts, waterproof and dustproof capacities. It adopts TPU material, which is soft, light and user-friendly. The enclosure is easy to be cleaned and disinfected with alcohol.",
        pic:"/ww5.png"
    },
    {
        category:"wearable",
        title:"WGL10217",
        text:"WGL10217 is a RFID beacon, IP67 Waterproof standard, and equipped by a push button to be used in emergency and SOS situations.",
        pic:"/wca3.png"
    },

    {
        category:"assets",
        title:"WGL10218",
        text:"WGL102218 is a sticker ibeacon, ultra-thin iBeacon, and leverage BLE4.0 technology, it is designed for the commercial advertising, asset tracking , indoor location-based service and personal electronic sticker.",
        pic:"/wh4.png"
    },
    {
        category:"assets",
        title:"WGL10219",
        text:"WGL10219 is a low energy Bluetooth 4.0 digital broadcasting device, it is designed for the commercial advertising, indoor location-based service, event monitor, asset tracking etc.",
        pic:"/wh5.png"
    },
    {
        category:"assets",
        title:"WGL10220",
        text:"WGL10220 is a Bluetooth 5.0 beacon with IP66 waterproof grade. It not only can be used for indoor location but also for outdoor. Besides, it can be fixed to the objects by screws mounting.",
        pic:"/WH6.png"
    },

    {
        category:"assets",
        title:"WGL10221",
        text:"WGL10221 is a low energy Bluetooth 4.0 digital broadcasting device, it is designed for the commercial advertising, indoor location-based service, event monitor, asset tracking etc.",
        pic:"/wm3.png",
    },
    {
        category:"assets",
        title:"WGL10222",
        text:"WGL10222 beacon is a bluetooth Low Energy proximity beacon. It is designed for humid application scenarios,as it has IP67 waterproof. It has been customized with accelerometer sensor for various applications, like asset tracking, advertisement at scenic and etc. It supports screw installation in some public places.",
        pic:"/whs1.png",
    },
    {
        category:"assets",
        title:"WGL10223",
        text:"WGL10223 Road Stud Beacon is featured with tough housing that against strong waterproof grade IP68, shockproof IK08, dustproof and explosion-proof. It can be fixed on the floor or objects by screws for locating and tracking. It can be deployed in both indoor and outdoor tough environments such as large supermarkets, construction site, factories, parking lots etc.",
        pic:"/WRS1.png",
    },
    {
        category:"assets",
        title:"WGL10224",
        text:"WGL10224 is loaded with Bluetooth 5.0 hardware platform. WGL10224 has an external ON/OFF push button with multiple functions. For example, it can be used as trigger to activate beacon to advertise different data by one click, double-click and triple-click.",
        pic:"/WR.png",
    },
    {
        category:"assets",
        title:"WGL10225",
        text:"WGL10225 is loaded with Bluetooth 5.0 iBeacon with stable RSSI signal specially used in indoor Location. With a smart rotatable mechanical design for the case, and it is easy to install.",
        pic:"/WRE.png",
    },



    {
        category:"sensors",
        title:"WGL10226",
        text:"WGL10226 is a Temperature and Humidity Sensor with high-precision digital thermohygrometer featuring Bluetooth Low Energy 5.0 technology. It is waterproof and comes equipped with an e-paper display screen, displaying real-time temperature and humidity data while consuming really low energy.",
        pic:"/WHT3.png",
    },
    {
        category:"sensors",
        title:"WGL10227",
        text:"WGL10227 is a BLE (Bluetooth Low Energy / version smart 5.0) Beacon with temperature & humidity sensor and accelerometer. It can store up to 200 historical data.",
        pic:"/WHT4.png",
    },
    {
        category:"sensors",
        title:"WGL10228",
        text:"WGL10228 Door and Window Sensor is loaded with Bluetooth Low Energy 5.0 hardware platform, can be installed on the doors, windows and even desk drawers. By detecting the opening and closing of doors and windows.",
        pic:"/wsd1.png",
    },

    {
        category:"sensors",
        title:"WGL10229",
        text:"WGL10229 is a light sensor beacon, utilize the latest and greatest technology Bluetooth 5.0 hardware platform. It can detect whether there is the ambient light or not. It is designed for the commercial advertising, indoor location-based service and specialized for the asset management and tracking application",
        pic:"/WEL2.png",
    },
    {
        category:"sensors",
        title:"WGL10230",
        text:"WGL10230 is IP67 waterproof grade and the smart rotatable mechanical housing design makes it user friendly. Moreover, WGL10230 Beacon with accelerometer advertises sensor data only when an object( WRP attached) is moving or after an object has fallen. Can be widely used in proximity, retail, navigation, tracking, etc.",
        pic:"/wrp1.png",
    },
    {
        category:"sensors",
        title:"WGL10301",
        text:"WGL10301 sensor can be used for sensing persons/animals movements. It can detect once something warm moving up to 5m away.",
        pic:"/WHD.png",
    },
    {
        category:"sensors",
        title:"WGL10401",
        text:"WGL10401 is a long range wireless sensor which can be used for distance measurement by using highly optimized ultrasonic sensor. For example, the sensor can be used as an indicator of the garbage level in the monitoring system.",
        pic:"/wud1.png",
    },

]


export default DataTags
