import React from 'react'
import "./home.css"
import UseCases from "./useCases"
import {RtlsIntro} from "./rtlsIntro"
import  {ApplicationDemo}  from './threeD/applicationDemo'


export default function Home(){
    return (
    <div>
     <section>
        <UseCases />
      </section>
      <section >
        <ApplicationDemo />
      </section>
      <section >
        <RtlsIntro />
      </section>
    </div>

    )
}

