export const DataTechnology = {
    
    Locating:[
        {
            title:"Locating Objects in Westigate RTLS solution",
            text:"BLE devices can be detected based on advertising packets. BLE tags advertise packets with an advertising interval and Bluetooth readers listen to frequency channels periodically (scan window) with an interval in order to discover BLE tags. Therefore, no pre-established connection between tags and reader is required, and new beacons can be added to the system at any time just with logging their identification number (mac address). In a real-time locating system, each Bluetooth beacon is calibrated with a Receive Signal Strength Indicator (RSSI) value that is seen with Bluetooth readers. The BLE readers are placed in a fixed and known position where it will scan the nearby tags and send the signals received from tags containing MAC address, RSSI, and raw data to the server. The RSSI value of the signal received with the readers is associated with the size of signal attenuation. In this solution, some offline RSSI values are stored in the database to compare with the RSSI values of the implemented system. Locating engine measures the distance of each tag from readers according to the reference RSSI values and radio propagation model. Eventually, by using trilateration technique, the location of each tag become determined.",
        },

    ],
    SensoryData : [
        {
            title:"Communicating Sensory Data",
            text:" In our sensory system, iBeacons and Eddystones send packets containing sensory data to the nearby readers. The core engine of the system analyses the raw layer of signals and provides a comprehensive environmental monitor and control system through Westigate platforms."
        }
    ],
    Bluetooth:[
    {
        title:"",
        text:"Bluetooth was developed as a wireless technology to stream and exchange data such as voice and video in a short-range between devices. Bluetooth devices must maintain the connection link even without data flow. After introducing Bluetooth 4.0 known as Bluetooth Low Energy (BLE) with location-oriented profiles, BLE has become a popular technology for locating applications. "
    },
 
    {
        title:"Power Consumption",
        text:"BLE devices such as Beacons advertise data with an interval and go to sleep mode until the next advertising. Also, the period of connections to send packets are around a few ms, while this time is around 100 ms for classic Bluetooth devices. Because of these functions, BLE devices have low power consumption and can work for several years with a coin-size battery. "
    },
    {
        title:"Operation Frequency",
        text:"BLE devices operate in 2.4 GHz  ISM band like classic Bluetooth, although their frequency-hopping spread-spectrum (FHSS) scheme are different."
    },
    {
        title:"Number of Connections Simultaneously",
        text:"Since BLE devices send small packet data, they can establish 20 connections at the same time, while Bluetooth devices can communicate with seven devices simultaneously."
    },
    {
        title:"",
        text:""
    },
    ],
    ReadersTags:[{
        title:"Westigate RTLS Readers",
        text:"RTLS readers are also known as RTLS gateways receive data from locating and sensory tags in range. The coverage of Westigate readers depends on the environment but approximately the best results can be achieved with one reader in each 25 square meters.",
        pic:"/readers.png",
        button:"View Our RTLS Readers",
        link:"/pages/hardware/readers"
    },
   {
        title:"Westigate RTLS Tags",
        text:"BLE tags are small devices attached to individuals or asset that broadcasts BLE signals in a range. BLE tags advertise their ID number, which identifies it to the system. A Bluetooth equipped device like RTLS readers can see a BLE tag once it is in range.  The RSSI value of signals received by the RTLS infrastructure is the base of the locating system in Westigate RTLS Solution.",
        pic:"/tags.png",
        button:"View Our RTLS Tags",
        link:"/pages/hardware/tags"
    }
    ],

    layers:[
    {
        title:"Layer 0- items",
        text:"Layer 0 is included any individual and item that you want to be tracked and located. "   

    },
    {
        title:"Layer 1- tags",
        text:"BLE tags are small devices attached to individuals or asset that broadcasts BLE signals in a range. BLE tags advertise their ID number, which identifies it to the system. A Bluetooth equipped device like RTLS readers can see a BLE tag once it is in range.  The RSSI value of signals received by the RTLS infrastructure is the base of the locating system in Westigate RTLS Solution."

    },
    {
        title:"Layer 2- Readers",
        text:"RTLS readers are also known as RTLS gateways receive data from locating and sensory tags in range. The coverage of Westigate readers depends on the environment but approximately the best results can be achieved with one reader in each 25 square meters. "
   
    },
    {
        title:"Layer 3- Middleware",
        text:"Middlewares acts as a bridge between RTLS infrastructure and servers. Middlewares collect data and interpret it to be readable for severs."
      },
    {
        title:"Layer 4- Servers and Database",
        text:""
    },
    {
        title:"Layer 5- Applications",
        text:"Westigate RTLS applications are to configure the system and visualize location data."
    },

    ]
}