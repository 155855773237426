import React from 'react';
import Navbar from './navbar';
import Sidebar from './sidebar';
import Submenu from './submenu';
import Home from './pages/home/home.js'
import {BrowserRouter as Router, Switch, Route} from "react-router-dom"
import Software from "./pages/software/software"
import Technology from "./pages/technology/technology"
import {Footer} from "./footer"
import Healthcare from "./pages/useCases/healthcare"
import Constrcution from "./pages/useCases/construction"
import {Factory} from "./pages/useCases/factory"
import {Readers} from "./pages/hardware/readers"
import {Tags} from "./pages/hardware/tags"
import ScrollToTop from './scrolToTop';
import MessengerCustomerChat from './messengerCustomer';
import ScrollApp from "./scrolTopButton"

function App() {
  // const [ipLocation, setIpLocation] = useState('')

  // const fetchData = () => {
  // fetch('https://ipapi.co/json/')
  // .then(response => response.json())
  // .then(data => setIpLocation(data) 
  // )}


  // const sendViewerDetail = () => {
  //   emailjs.send('service_aznfkrb', 'template_2ws10pm', ipLocation, 'user_MuFz7VDHOUjCBKep0ZkuW')
  // }

  //   useEffect(() => {
  //     fetchData()
  //     sendViewerDetail()
  //   }, [])
  return (
    <>
    <Router >
      <ScrollToTop />
      <Navbar />
      <Sidebar />
      <Submenu />
      <Switch>
      <Route path="/" exact component={Home} />
      <Route path="/pages/useCases/healthcare" component={Healthcare} />
      <Route path="/pages/useCases/construction" exact component={Constrcution} />
      <Route path="/pages/useCases/factory" exact component={Factory} />
      <Route path="/pages/hardware/tags" exact component={Tags} />
      <Route path="/pages/hardware/readers" exact component={Readers} />
      <Route path="/pages/software" exact component={Software} />
      <Route path="/pages/technology" exact component={Technology} />
      </Switch>
      <MessengerCustomerChat
          className="fb-customerchat"
          pageId="113344314127105"
          appId="255926846005862"
      />
      <ScrollApp />
      <Footer />
      </Router>
      
    </>
  );
}

export default App;