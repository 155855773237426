import React from 'react';
 import "./home.css"
 import "../hardware/hardware.css"
 import {DataBenefits} from "./dataHome"
 import {Implementation} from "./dataHome"
 import {Link} from "react-router-dom"
 import {SiCheckmarx} from "react-icons/si"




export const RtlsIntro = () => {

    return (
        <div>

            <div className="rtlsBenefitsBackground" style={{backgroundImage:`url(/aboutUs.png)`}}  >
            <div className="rtlsBenefits" style={{paddingTop:"200px", paddingBottom:"200px"}}>
                <h2>Why you should use RTLS<div className="underline"></div></h2>
            {DataBenefits.map(count => {
                const {pic, text, index} = count
                return(
                    <div className="colBenefits" key={index}>
                        <div className="imageContainerCircle" >
                        <img className="image" src={pic} alt=""/></div>
                        <h4 style={{padding:"20px"}}>{text}</h4>
                        </div> 
                )
            })}</div></div>

                <div className="textImage" style={{minHeight:"100vh"}}>
                <div className="imageContainerHome">
                    <img className="imageHome" src="/RTLS_Structure.png" alt=""></img></div><div className="textContainerHome"><h2>our RTLS technology</h2>
                <div className="underline"></div>
                <p>
                Westigate RTLS Solution is technology agnostic with the possibility
                to integrate with Bluetooth, WiFi, UWB, and RFID technologies. We consider 
                your infrastructure and needs, and then design the system according to your requirements.
                Among the standards of RTLS communication, Bluetooth is offering the best options for
                individual and asset tracking because of its area coverage, the number of tags supported,
                the battery lifetime of tags, and its low cost in comparison to other standards. We recommend 
                using Bluetooth in RTLS with the flexibility to use other communication technologies in some cases.
                 </p></div>
            </div>


            <div className="textImage" style={{background: "rgba(0,0,0, 0.15)", minHeight:"100vh"}}>
                <img src="/tags.png" style={{width:"25%"}} alt=""/>
                <ul >
                <h2 style={{margin:"50px 10px", color:"black"}}>Our RTLS Hardware<div className="underline" style={{margin:"15px 10px"}}></div></h2>
                <li ><h3 style={{fontSize:"1.3rem", color:"black"}}>Bluetooth Protocol</h3></li>
                <li><h3 style={{fontSize:"1.3rem", color:"black"}}>Tracking Mobile Phones and Beacons</h3></li>
                <li><h3 style={{fontSize:"1.3rem", color:"black"}}>Up to One Meter Accuracy</h3></li>
                <li><h3 style={{fontSize:"1.3rem", color:"black"}}>Communicating Data Through WiFi and Ethernet Cable</h3></li> 
                <div style={{textAlign:"center"}}><Link to="/pages/hardware/readers"><button className="button" style={{marginRight:"10px"}}>View RTLS Readers</button></Link><Link to='/pages/hardware/tags'><button className="button">View RTLS Tags</button></Link>
               </div>
               </ul>
            <img src="/readers.png" style={{width:"25%"}} alt=""/></div>
            

           <div style={{backgroundColor:"black", minHeight:"100vh", paddingTop:"150px"}}>
                <div className="textImage" style={{color:"white"}}><div className="textContainerHome"><h2>westigate sensory system</h2>
                <div className="underline"></div>
                <p>
                Our sensory system monitors temperature, humidity, light,
                 and power consumption in your hospital. Westigate sensory system sends an 
                 instant notification through SMS, Email, and applications, if the environmental conditions pass predefined thresholds.
                 </p></div>
                    <div className="imageContainerHome">
                    <img className="imageHome" src="/Sensory_System_Westigate.png" alt="" /></div></div>
            </div>
            <div>
            <div className="textImage" style={{alignItems:"center", minHeight:"100vh", alignContent:"center", 
            background: "rgba(0,0,0,0.1)"}}>
            
            <div className="imageContainerHome">
                <img className="imageHome" src="/softwareApplications.png" alt=""/>
            </div>
            <div className="textContainerHome">
            <div><h2 style={{width:"100%", textAlign:"left"}}>Westigate Applications<div className="underline" style={{margin:"15px 0px"}}></div></h2></div>
            <div className="softwareLayers" >
            <div className="softwareRows"><SiCheckmarx /><h4 >access from anywhere through a web browser</h4></div>
            <div className="softwareRows"><SiCheckmarx /><h4 >Highly customizable </h4></div>
            <div className="softwareRows"><SiCheckmarx /><h4 >User-friendy UI </h4></div>
            <div className="softwareRows"><SiCheckmarx /><h4 >2D Map, Isometric 3D map, List, and Graph views</h4></div>
            <div className="softwareRows"><SiCheckmarx /><h4 >heat map on 2D and isometric 3D </h4></div>
            <div className="softwareRows"><SiCheckmarx /><h4 >customizable layouts</h4></div>

            <Link to='/pages/software'> <button className="button" style={{width:"50%", justifySelf:"center"}} >Read More</button></Link>
            </div> 
            </div>
            </div>

        </div>
        <div>
        <div className="introductionText" style={{paddingBottom:"100px"}}>
        <h2 style={{textAlign:"center"}}>RTLS Implementation<div className="underline"></div></h2>
        <p>A key to sustainable change is to start with clear goals. And, to plan and have supporting resources for the ongoing work. Westigate can help ensure your technology is addressing the right challenges. This is accomplished through our pro-organization and ultimately driving measurable, sustainable improvements.</p>
            {Implementation.map(count => {
                const {title, text} = count
                return(
                    <div style={{marginTop:"15px", paddingLeft:"5%", paddingRight:"5%"}} >
                        <h4 style={{display:"inline"}}>{title}:</h4><span style={{display:"inline"}}>{text}</span>
                    </div>
                )
            })}
        </div></div>
        <div className="introductionText" style={{minHeight:"100vh", marginTop:"0px", paddingTop:"15%", alignItems:"center", backgroundColor:"rgba(31, 9, 47, 1)", color:'white'}}>
            <h2 style={{textAlign:"center"}}>About Us<div className="underline"></div></h2>
            <p>At Westigate we provide smart Information Technology and Internet of Things solutions to address challenges within different industries and particularly the healthcare sector.  We take pride in introducing the Real-Time Locating solution to transform your business into the next level of visualization. 
            <br />Our vision is to increase productivity, efficiency, safety, and profitability in companies by applying smart solutions</p>
        </div>
            </div>
    )
}
