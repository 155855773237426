import React, { useRef} from "react";
import { useGLTF} from '@react-three/drei';

export default function BedModel({position}) {

    const mesh = useRef(null)
    const gltf = useGLTF("/scene.gltf", true);

    // useFrame(() => {
    //     return(
    //     //    mesh.current.rotation.y +=0.007,
    //        mesh.current.position.x +=0.3
    // )})
 
    return (
        <>
            <ambientLight intensity={0.4} />
            <directionalLight position={[10,10,5]} intensity={1} />

            <mesh position={position} ref={mesh} rotation={[0,0,0]} scale={[3,3,3]}>
                <primitive object={gltf.scene} dispose={null} />
            </mesh>

        </>
        )

}
