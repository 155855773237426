import React from 'react'
import {AiOutlineMail} from "react-icons/ai"
import {AiOutlineLinkedin} from "react-icons/ai"
import {Link} from "react-router-dom"

export const Footer = () => {
    return (
        <>
        <div className="footer">
            <div className="links">
                <h3>Links</h3>
                <div style={{display:"flex", flexDirection:"row"}}>
              <ul className="links1">
                <li><Link style={{color:"white"}} to="/"><h4>Home</h4></Link></li>
               <li> <Link style={{color:"white"}} to="/pages/useCases/healthcare"><h4>RTLS in Healthcare</h4></Link></li>
                <li><Link style={{color:"white"}} to="/pages/useCases/factory"><h4>RTLS in Manufacturing </h4></Link></li>
               <li> <Link style={{color:"white"}} to="/pages/useCases/construction"><h4>RTLS in construction sites</h4></Link></li>
            </ul>
            <ul className="links2">
            <li><Link style={{color:"white"}} to="/pages/software"><h4>Software</h4></Link></li>
            <li> <Link style={{color:"white"}} to="/pages/hardware/readers"><h4>RTLS readers</h4></Link></li>
            <li><Link style={{color:"white"}} to="/pages/hardware/tags"><h4>RTLS tags</h4></Link></li>
            <li><Link style={{color:"white"}} to="/pages/technology"><h4>Technology</h4></Link></li>
            </ul>
            </div></div>

        <div className="iconsFooter" >
            <div style={{width:"20%"}}><a href='https://www.linkedin.com/company/wesitgate'> <h4 style={{color:"white"}}>Follow us</h4><AiOutlineLinkedin style={{fontSize:"40px",color:"white"}}/></a></div>
            <div style={{marginLeft:"5px"}}><h4>contact us</h4><AiOutlineMail style={{fontSize:"40px"}}/><h4 style={{textTransform:"lowercase"}}>info@Westigate.com</h4></div>
        </div>

 </div>
 <div style={{textAlign:"center", alignItems:"center", height:"30px", width:"100%", backgroundColor:"rgba(0,0,0,0.2)"}}><h4>Copyright © Westigate Ltd. All rights reserved</h4></div>
 </>
    )
}
