
import React, { Suspense} from "react";
import { Canvas } from "react-three-fiber";
import { OrbitControls } from '@react-three/drei';
import BedModel from './bedModel'
import HospitalModel from './hospitalModel'
// import { useSpring, a } from "react-spring/three";

export const ApplicationDemo = () => {
  return (
    <div>
     <div className="textAnimation">
        <div className="intro"><h2 style={{textAlign:"center"}}>What is RTLS?</h2><div className="underline"></div>
        <p>RTLS stands for the real-time locating system. It is an indoor locating system that tracks 
             tagged objects consisting of assets and people via radiofrequency. RTLS gives you the ability to
              monitor workflows and processes, movements of assets/people, and environmental conditions as well
               as determining rules/ alerts processes automatically and more safely and consistently.</p>
        </div>
    <div className="animation" >

      <Canvas colorManagement camera={{position:[5, 200, 400], fov: 30}}>
        <Suspense fallback={null}>
            <OrbitControls />
            <HospitalModel />
            <BedModel position={[-70,3,-30]} />
        </Suspense>
      </Canvas>
      </div>
    </div>
    </div>
  );
};





