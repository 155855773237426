import React, { useState, useRef, useEffect } from 'react'
import { useGlobalContext } from './context'
import { Link } from "react-router-dom"

const Submenu = () => {
  const[imageSubMenu, setImageSubMenu] = useState(true)

  const {
    isSubmenuOpen,
    page: { page, links },
    location,
  } = useGlobalContext()
  const container = useRef(null)
  useEffect(() => {
    const submenu = container.current
    const { center, bottom } = location
    submenu.style.left = `${center}px`
    submenu.style.top = `${bottom}px`
  }, [page, location, links])
  return (
    <aside
      className={`${isSubmenuOpen ? 'submenu show' : 'submenu'}`}
      ref={container}
    >
      <section>
        <div className="submenu-center" >
          <div>
          {links.map((link, index) => {
            const { url, label} = link
            return (
              <Link key={index} to={url} onMouseOver={() => {
                  setImageSubMenu(label)}}
                  onMouseLeave={() => {
                    setImageSubMenu("/r18_1.png")
                  }}
                  >
                {label}
              </Link>
            )
          })}</div>

          <div>
          <img style={{width:"150px", height:"150px", backgroundColor:"grey", border:"1px solid white"}} src={
            (imageSubMenu === "Construction") ? "/construction.png" 
            : (imageSubMenu === "Manufacturing") ? "/factoryHeadingPic.jpg" 
            : (imageSubMenu === "Healthcare") ? "/hospital_RTLS.jpg" 
            : (imageSubMenu === "Readers") ? "/readers.png" 
            : (imageSubMenu === "Tags") ? "/tags.png" : "/r18_1.png"} alt='Westigate' />
            </div>
            
        </div>
      </section>
    </aside>
  )
}

export default Submenu
