const sublinks = [
  {
    page: 'Home',
    pageLink:'/',
    links:[],
    id:"11111"
  },
  {
    page: 'Use-Cases',
    pageLink:'',
      links: [
      { label: 'Healthcare', url: '/pages/useCases/healthcare' },
      { label: 'Construction', url: '/pages/useCases/construction' },
      { label: 'Manufacturing', url: '/pages/useCases/factory'},
    ],
  },
  {
    page: 'Hardware',
    pageLink:'',
    links: [
      { label: 'Readers', url: '/pages/hardware/readers' },
      { label: 'Tags', url: '/pages/hardware/tags' },
    ],
  },
  {
    page: 'Software',
    pageLink:'/pages/software',
    links: [],
  },
  {
    page: 'Technology',
    pageLink:'/pages/technology',
    links:[],
  },
];

export default sublinks;
