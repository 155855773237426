import React from "react"
import {FaLocationArrow} from "react-icons/fa"

class ScrollButton extends React.Component {
    constructor() {
      super();
  
      this.state = {
          intervalId: 0
      };
    }
    
    scrollStep() {
      if (window.pageYOffset === 0) {
          clearInterval(this.state.intervalId);
      }
      window.scroll(0, window.pageYOffset - this.props.scrollStepInPx);
    }
    
    scrollToTop() {
      let intervalId = setInterval(this.scrollStep.bind(this), this.props.delayInMs);
      this.setState({ intervalId: intervalId });
    }
    
    render () {
        return <button title='Back to top' className='scroll' 
                 onClick={ () => { this.scrollToTop(); }}>
                     <FaLocationArrow size="20" style={{color:"white", transform:"rotate(-45deg)"}}/>
                  <span className='arrow-up glyphicon glyphicon-chevron-up'></span>
                </button>;
     }
  } 
  
class ScrollApp extends React.Component {
    render () {
      return <ScrollButton scrollStepInPx="50" delayInMs="2.66"/>
             
    }
  }


  export default ScrollApp