import React from 'react';
import { FaBars } from 'react-icons/fa';
import { useGlobalContext } from './context';
import { Link } from "react-router-dom"

const Navbar = () => {
  const { openSidebar, openSubmenu, closeSubmenu } = useGlobalContext();
  const displaySubmenu = (e) => {
    const page = e.target.textContent;
    const tempBtn = e.target.getBoundingClientRect();
    const center = (tempBtn.left + tempBtn.right) / 2;
    const bottom = tempBtn.bottom - 3;
    openSubmenu(page, { center, bottom });
  };
  const handleSubmenu = (e) => {
    if (!e.target.classList.contains('link-btn')) {
      closeSubmenu();
    }
  };
  return (
    <nav className= 'nav' onMouseOver={handleSubmenu}>
      <div className='nav-center'>
        <div className='nav-header'>
          <Link to="/" ><img src="/westigate-logo.png" className='nav-logo' alt='Westigate'/></Link>
          <button className='btn toggle-btn' onClick={openSidebar}>
            <FaBars />
          </button>
        </div>
        <ul className='nav-links'>
          <li>
            <Link to="/">
            <button className='link-btn'>
              Home
            </button></Link>
          </li> 
          <li>
          
            <button className='link-btn' onMouseOver={displaySubmenu}>
              Use-Cases
            </button>
          </li>
          <li>
            <button className='link-btn' onMouseOver={displaySubmenu}>
              Hardware
            </button>
          </li>
          <li>
            <Link to="/pages/software"><button className='link-btn'>
              Software
            </button></Link>
          </li>
          <li>
            <Link to="/pages/technology"><button className='link-btn'>
              Technology
            </button></Link>
          </li>
          {/* <li>
            <Link to="/pages/chatApp/contactUs"><button className='link-btn'>
              <div className="imageContainer" ><img className="image"  src="/liveChat.png" /></div>
            </button></Link>
          </li> */}
        </ul> 
        </div>
        <div>
        </div>

    </nav>
  );
};

export default Navbar;
