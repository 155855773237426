import React from 'react'
import Animation from "./animation"
import {DataTechnology} from "./DataTechnology"
import {BsArrowRight} from "react-icons/bs"
import {Link} from "react-router-dom"


function Technology(){

    return (
        <div>
       <div className="canvas" >
      <Animation style={{position:"absolute", top:"0px", left:"0px"}}/><h1 style={{position:"absolute", top:"50%", color:"white", left:"10px", backgroundColor:"rgba(0,0,0,0.8)", padding:"0px"}}>Bluetooth Technology in RTLS</h1>
        </div>

        <div style={{display:"flex",flexDirection:"column",textAlign:"center", marginTop:"150px", width:"100%", justifyContent:"center"}}>
            <h2>Locating Objects in Westigate RTLS solution</h2><div className="underline" /></div>
        <div className="textImage" style={{marginLeft:"10px", paddingTop:"10px"}}>
            <div className="textContainer">
                <p>BLE devices can be detected based on advertising packets. BLE tags advertise packets with an advertising interval and Bluetooth readers listen to frequency channels periodically (scan window) with an interval in order to discover BLE tags. Therefore, no pre-established connection between tags and reader is required, and new beacons can be added to the system at any time just with logging their identification number (mac address). In a real-time locating system, each Bluetooth beacon is calibrated with a Receive Signal Strength Indicator (RSSI) value that is seen with Bluetooth readers. The BLE readers are placed in a fixed and known position where it will scan the nearby tags and send the signals received from tags containing MAC address, RSSI, and raw data to the server. The RSSI value of the signal received with the readers is associated with the size of signal attenuation. In this solution, some offline RSSI values are stored in the database to compare with the RSSI values of the implemented system. Locating engine measures the distance of each tag from readers according to the reference RSSI values and radio propagation model. Eventually, by using trilateration technique, the location of each tag become determined.</p>
                </div>
               <div className="imageContainer">
                    <Link to="/pages/software" ><img style={{width:"90%"}} src="/RTLS-Layers5.png" alt=""/></Link>
                    <Link to= "/pages/technology"><img style={{width:"90%"}} src="/RTLS-Layers4.png" alt=""/></Link>
                    <Link to= "/pages/technology"><img style={{width:"90%"}} src="/RTLS-Layers3.png" alt=""/></Link>
                    <Link to="/pages/hardware/readers"><img style={{width:"90%"}} src="/RTLS-Layers2.png" alt=""/></Link>
                    <Link to="/pages/hardware/tags"><img style={{width:"90%"}} src="/RTLS-Layers1.png" alt=""/></Link>
                </div>

        </div>
        <div>
            <div style={{justifyContent:"center", margin:"100px 20px", textAlign:"center"}}>
          <h2>Communicating Sensory Data<div className="underline" style={{textAlign:"center"}}></div></h2>
          <p style={{textAlign:"left"}}>
          In our sensory system, iBeacons and Eddystones send packets containing sensory data to the nearby readers. The core engine of the system analyses the raw layer of signals and provides a comprehensive environmental monitor and control system through Westigate platforms.
          </p>
        </div></div>

        <div style={{background: 'rgba(0,0,0,0.1)'}}>
            {DataTechnology.ReadersTags.map(count => {
                const {title, text, pic, button, link} = count
                return(
                    <div className="textImage">
                        <div className="textContainer" >
                            <h3 style={{textAlign:"center"}}>{title}<div className="underline" ></div></h3>
                            <p>{text}</p>
                           <Link to={link}><div style={{textAlign:"center", alignItems:"center", cursor:"pointer"}}> <h4>{button}<BsArrowRight size={30} /></h4> </div></Link>
                            </div>
                        <div className="imageContainer">
                            <img src={pic}  style={{width:"50%", border:"1px solid black", backgroundColor:"black"}} alt="" />
                            </div>
                    </div>
                )
            })}

        </div>

        <div className="introductionText" style={{backgroundColor:"rgba(0,0,0,0.01)", marginTop:"0", paddingTop:"200px"}}>
        <h2>Bluetooth Low Energy Versus Classic Bluetooth<div className="underline"></div></h2>
            {DataTechnology.Bluetooth.map(count => {
                const {title, text} = count
                return(
                    <div>
                    <h3>{title}</h3>
                    <p>{text}</p>
                    </div>
                )
            })}
        </div>

        </div>
    )
}

export default Technology
