import React, { useRef, useEffect, useState} from "react";
import { TextureLoader } from "three/src/loaders/TextureLoader.js";
import {useLoader, useFrame} from "react-three-fiber";
import * as THREE from "three";
import {useGLTF} from '@react-three/drei';
import {useSpring, a} from "react-spring/three"

export default function HospitalModel() {

const [nursePos, setNursePos] = useState([0,0,-30])
const [wheelPos, setWheelPos] = useState([nursePos[0], nursePos[1], nursePos[2] + 20])
const [scaleStatus, setScaleStatus] = useState(false)

let nurseTimer
let markerTimer

  const colPos = [
    {target:  [-75,25,-75]},
    {target:   [75,25,-75]},
    {target: [75,25,75]},
    {target : [-75,25,75]}
  ]

  const readerPosition =[
    {pos: [-70,25,-71] , angle:[0,Math.PI/4,0]},
    {pos: [70,25,-71] , angle:[0,-Math.PI/4,0]},
    {pos: [-70,25,71] , angle:[0,Math.PI/1.33,0]},
    {pos: [70,25,71] , angle:[0,-Math.PI/1.33,0]}
  ]

  const borders = [-75, 75, 25]

  const nurseM = useRef(null)
  const wheelM = useRef(null)
  const markerM = useRef(null)
  const markerMTwo = useRef(null)

  const nurseModel = useGLTF("/nurse.gltf", true);
  const wheelModel = useGLTF("/wheelchair.gltf", true);
  const markerModel = useGLTF("/marker.gltf", true)
  const markerModelTwo = useGLTF("/markerTwo.gltf", true)

  const props = useSpring( {
    scale : scaleStatus ? [30,30,30] : [15,15,15]
})
 
useFrame(()=> {
  return(
        nurseM.current.position.z < borders[1] - 10 ? 
        nurseM.current.position.z += 0.05 :
        nurseM.current.position.z = borders[0]
  )
})
useFrame(()=> {
  return(
        wheelM.current.position.z = nurseM.current.position.z + 7
  )
})
useFrame(()=> {
  return(
        markerM.current.position.z = nurseM.current.position.z 
  )
})
useFrame(()=> {
  return(
        markerMTwo.current.position.z = wheelM.current.position.z 
  )
})

useEffect(() => {
  nurseTimer = setInterval(() =>{
      setNursePos([nurseM.current.position.x, nurseM.current.position.y, nurseM.current.position.z ])
      setWheelPos([wheelM.current.position.x, wheelM.current.position.y, wheelM.current.position.z ])
  }, 1000)
  return () => {
    clearInterval(nurseTimer)
  }
}, [nursePos])

useEffect(() => {
  markerTimer = setInterval(() =>{
      setScaleStatus(!scaleStatus)
  }, 1000)
  return () => {
    clearInterval(markerTimer)
  }
}, [scaleStatus])


  return (
    <>  
            
            {readerPosition.map(count => {
              return(
                <>
                <Signals orgCoords={nursePos} tgtCoords={count.pos} borders={borders}/>
                </>
              )
            })}
            {readerPosition.map(count => {
              return(
                <Signals orgCoords={wheelPos} tgtCoords={count.pos} borders={borders}/>
              )
            })}
            {readerPosition.map(count => {
              return(
                <Readers position={count.pos} angle={count.angle} />
              )
            })}
            {colPos.map(count => {
              return(
                <Col position={count.target} />
              )
            })}
            <Room />
            <TableModel position={[-67,8,-10]} scale={[0.8,0.8,0.8]}/>
            <ambientLight intensity={0.4} />
            <directionalLight position={[10,25,0]} intensity={1} />

            <mesh position={[0,0,-75]} ref={nurseM} scale={[0.1,0.1,0.1]} >
                <primitive object={nurseModel.scene} dispose={null} />
            </mesh>

            <mesh position={[0,6,-55]}  ref={wheelM} scale={[6,6,6]}>
                <primitive object={wheelModel.scene} dispose={null} />
            </mesh>
            
            <a.mesh position={[0,30,-75]}  ref={markerM} scale={props.scale}>
                <primitive object={markerModel.scene} dispose={null} />
            </a.mesh>
            <a.mesh position={[0,12,-55]}  ref={markerMTwo} scale={props.scale}>
                <primitive object={markerModelTwo.scene} dispose={null} />
            </a.mesh>

           
    </>
  )
}




function Signals({orgCoords, tgtCoords, borders}) {
  const bluetooth = useRef(null)
  const speed = 0.05

  const direction = new THREE.Vector3()
  const targetVector = new THREE.Vector3(...tgtCoords)



  useEffect(() => void bluetooth.current.lookAt(targetVector), [targetVector])

    useFrame(() => {
    const { position } = bluetooth.current
    direction.subVectors(targetVector, position)
    const vector = direction.multiplyScalar(speed, speed, speed)
      if((bluetooth.current.position.x > borders[0] + 0.1 && bluetooth.current.position.x < borders[1] - 0.1) &&
      (bluetooth.current.position.z > borders[0] + 0.1 && bluetooth.current.position.z < borders[1] - 0.1) &&
      (bluetooth.current.position.y < borders[2] - 0.1)
      ){
        bluetooth.current.position.x += vector.x
        bluetooth.current.position.y += vector.y
        bluetooth.current.position.z += vector.z
      }else{
        bluetooth.current.position.x = orgCoords[0]
        bluetooth.current.position.y = orgCoords[1]
        bluetooth.current.position.z = orgCoords[2]
      }

    
  })

  return (
    <>
            <mesh position={orgCoords} rotation={[-Math.PI/2, 0, 0]} ref={bluetooth}>
                  <sphereBufferGeometry args={[0.5,100]}/>
                  <meshBasicMaterial attach="material" color="blue" />
            </mesh>
    </>
  )
}



function Col({position}) {

return(
  <>
        <mesh position={position}>
            <boxBufferGeometry attach="geometry" args={[10,50,7.5]} />
            <meshStandardMaterial attach="material" color="rgba(0,0,0,0.1)" />

        </mesh>
    </>
  )
}


function Readers({position, angle}) {
  return (
    <>
          <mesh position={position} rotation={angle}>
            <boxBufferGeometry args={[5,5,1]} />
            <meshBasicMaterial attach="material" color="white" />

          </mesh>
          <mesh position={position} rotation={angle}>
            <boxBufferGeometry args={[0.5,2,1]} />
            <meshBasicMaterial attach="material" color="green" />
          </mesh>
    </>
  )
}



function Room() {
  const texture_1 = useLoader(TextureLoader, '/taleFloor.jpg');
  const color="darkgreen"
  return (
    <>

           <mesh position={[0,0,0]} rotation={[-Math.PI/2, 0, 0]}>
                  <planeBufferGeometry attach="geometry" args={[150,150]}  />
                  <meshStandardMaterial map={texture_1} attach="material" color="grey" />
            </mesh>
            <mesh position={[-40,12.5,-75]} rotation={[0, 0, 0]}>
              <planeBufferGeometry attach="geometry" args={[60,25]}  />
              <meshStandardMaterial attach="material" color={color} />

            </mesh>
            <mesh position={[40,12.5,-75]} rotation={[0, 0, 0]}>
              <planeBufferGeometry attach="geometry" args={[60,25]}  />
              <meshStandardMaterial attach="material" color={color} />

            </mesh>
            <mesh position={[0,22.5,-75]} rotation={[0, 0, 0]}>
              <planeBufferGeometry attach="geometry" args={[30,5]}  />
              <meshStandardMaterial attach="material" color={color} />

            </mesh>

            <mesh position={[-40,12.5,+75]} rotation={[0, 0, 0]}>
              <planeBufferGeometry attach="geometry" args={[60,25]}  />
              <meshStandardMaterial attach="material" color={color} />

            </mesh>
            <mesh position={[40,12.5,+75]} rotation={[0, 0, 0]}>
              <planeBufferGeometry attach="geometry" args={[60,25]}  />
              <meshStandardMaterial attach="material" color={color} />

            </mesh>
            <mesh position={[0,22.5,+75]} rotation={[0, 0, 0]}>
              <planeBufferGeometry attach="geometry" args={[30,5]}  />
              <meshStandardMaterial attach="material" color={color} />

            </mesh>

            <mesh position={[75,12.5,0]} rotation={[0, -Math.PI/2, 0]}>
              <planeBufferGeometry attach="geometry" args={[150,25]}  />
              <meshStandardMaterial attach="material" color={color} />


            </mesh>
            <mesh position={[-75,12.5,0]} rotation={[0, Math.PI/2, 0]}>
              <planeBufferGeometry attach="geometry" args={[150,25]}  />
              <meshStandardMaterial attach="material" color={color} />


        </mesh>
    </>
  )
}

function TableModel({position, scale}) {

  const mesh = useRef(null)
  const gltf = useGLTF("/table.gltf", true);

  // useFrame(() => {
  //     return(
  //     //    mesh.current.rotation.y +=0.007,
  //        mesh.current.position.x +=0.3
  // )})

  return (
      <>
          <ambientLight intensity={0.4} />
          <directionalLight position={[10,10,5]} intensity={1} />

          <mesh position={position} ref={mesh} rotation={[0,0,0]} scale={scale} rotation={[0,Math.PI/2,0]}>
              <primitive object={gltf.scene} dispose={null} />
          </mesh>

      </>
      )

}







// const vertices = [
//   [-10, -10, 10], // 0
//   [(10, -10, 10)], // 1
//   [-10, 10, 10], // 2
//   [10, 10, 10], // 3
//   [-10, -10, -10], // 4
//   [10, -10, -10], // 5
//   [-10, 10, -10], // 6
//   [10, 10, -10] // 7
// ];

// const faces = [
//   // front
//   [0, 3, 2],
//   [0, 1, 3],
//   // right
//   [1, 7, 3],
//   [1, 5, 7],
//   // back
//   [5, 6, 7],
//   [5, 4, 6],
//   // left
//   [4, 2, 6],
//   [4, 0, 2],
//   // top
//   [2, 7, 6],
//   [2, 3, 7],
//   // bottom
//   [4, 1, 0],
//   [4, 5, 1]
// ];

// return (
//   <>  
//       <mesh >
//             <geometry
//               attach="geometry"
//               vertices={vertices.map(v => new THREE.Vector3(...v))}
//               faces={faces.map(f => new THREE.Face3(...f))}
//             />
//             <meshBasicMaterial attach="material" color="pink" />
//       </mesh>
//       <mesh position={[0,20,20]}>
//           <geometry attach="geometry">
//             <vector3 attachArray="vertices" args={[0, 0, 0]}></vector3>
//             <vector3 attachArray="vertices" args={[1, 0, 0]}></vector3>
//             <vector3 attachArray="vertices" args={[1, 1, 0]}></vector3>
//             <face3 attachArray="faces" args={[0, 1, 2]}></face3>
//           </geometry>
//           <meshBasicMaterial attach="material" color="red" />
//       </mesh>